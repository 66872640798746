<template>
  <main class="tour-confirm w-100">
    <section class="tour-confirm__container">
      <h1 class="title tour-confirm__title">{{ $t("Transaction details") }}</h1>
       <h3 class="text-direction-align mt-15">{{ $t("Please, select the customer") }}</h3> 
      <div 
        class="tour-confirm__item"
        v-for="(passenger, index, kk) in passengers"
        :key="index"
      >
        <h3 class="text-direction-align">{{ $t("Room") }} {{kk + 1}}</h3>
        <label class="label tour-confirm__label">{{ $t("Which of the passengers is the customer") }}</label>
        <vue-select 
          :options="passenger.options" 
          class="input cus-select"
          v-model="passenger.selected"
          @input="selectCustomer(index)"
        >
        </vue-select>
      </div>

      <p class="text tour-confirm__text">{{ $t("Booking details travel dates Extras Destination") }}</p>

      <div class="tour-confirm__wrapper">
        <b class="tour-confirm__price">{{ finalPrice }} </b>
        <b class="tour-confirm__current">{{ $t("NIS") }}</b>
      </div>

      <button 
        class="link tour-confirm__link"
        @click="confirmOrder"
      >
        {{ $t("Order Confirmation") }}
      </button>

      <p class="text tour-confirm__text-bottom">{{ $t("By clicking on an order confirmation you confirm") }}</p>

      <a class="link tour-confirm__policy" href>{{ $t("Site's Policy") }}</a>
    </section>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "OrderConfirmation",
  computed: {
    ...mapState({
      loading: state => state.tour_order.loading,
      passportDetail: state => state.tour_order.passportDetail      
    }),
    transactionId() {
      return localStorage.getItem("transactionId");
    },
    phoneNumber() {
      return localStorage.getItem("phone_number");
    },
    finalPrice() {
      return localStorage.getItem("finalPrice");
    }
  },
  async created() {
    const transaction_id = this.$route.params.transaction_id;
    const phoneNumber = this.$route.params.phone_number;

    this.passportData = this.passportDetail;
    if(Object.keys(this.passportData).length == 0) {
      this.passportData =  JSON.parse(localStorage.getItem('passportData'));
    }
 
    if(Object.keys(this.passportData).length != 0) {
      for (let key in this.passportData) {
        if(typeof this.passengers[key] == "undefined") {
          this.passengers[key] = {};
        }
        for (let index in this.passportData[key]) {
          let fullName = this.passportData[key][index].first_name + this.passportData[key][index].last_name;
          
          if(typeof this.passengers[key].options == "undefined") {
            this.passengers[key].options = [];
          }
          this.passengers[key].options.push({
            "label": fullName,
            "code": index
          });
        }
        // this.passengers[key].selected = this.passengers[key].options[0];
      }
    }
  },
  data() {
    return {
      passengers: {},
      passportData: {},
      rNumber: 0
    }
  },
  methods: {
    ...mapActions("tour_order", {
      confirmTransaction: "confirmTransaction"
    }),
    selectCustomer(roomNumber) {
      this.passportData[roomNumber].forEach(ele => {
        ele.is_customer = 0;
      });
      let index = this.passengers[roomNumber].selected.code;
      this.passportData[roomNumber][index].is_customer = 1;
    },
    confirmOrder() {
      const confirmationData = {
        'phone_number': localStorage.getItem("phone_number"),
        'passport_details': this.passportData
      }

      const transaction_id = localStorage.getItem("transactionId")
      this.confirmTransaction({
        id: transaction_id,
        cData: confirmationData
      });
    }
  }
};
</script>